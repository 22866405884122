<template>
    <div class="bg-grey200">
        <div class="max-w-container md:max-w-md sm:max-w-sm mx-auto pt-[4.5rem] pb-8 flex flex-col items-center">
            <h2 class="font-berlingske text-4.5xl sm:text-[1.875rem] leading-[3.25rem] text-center sm:leading-[2.125rem] mb-8 sm:px-8 sm:font-black">
                Ils se sont lancés, pourquoi pas vous ?
            </h2>

            <c-frise
                id="container-frise-home2"
                :index-start="0"
                :index-end="successStories.length - 1"
                :coefGap="0.065"
                color-selected="#004D43"
                :show-arrows-with-dot="isMobile"
                custom-last
                class="sm:!max-w-[90vw]"
            >
                <nuxt-link
                    v-for="(story, index) in successStories"
                    :key="index" :to="{path: '/success-story/details/' + story.urlSlug}"
                    class="p-3 sm:p-0 min-w-[17.625rem] sm:min-w-[21.563rem] w-full sm:mr-[.938rem] sm:mb-8 group hover:bg-forest-light rounded"
                >
                    <div>
                        <div class="relative mb-4 w-full">
                            <nuxt-img
                                format="webp"
                                densities="1x"
                                sizes="lg:400px"
                                width="400"
                                quality="100"
                                :src="getImageSuccessStory(story.id, story.imageBienHome ? 'bienHome' : 'bien')"
                                class="w-full h-52 sm:h-72 rounded sm:mb-[.625rem] sm:mt-[.625rem] object-cover"
                                loading="lazy"
                                :alt="story.altImageBien">
                            </nuxt-img>

                            <div
                                class="w-[5.063rem] h-[5.063rem] absolute bottom-2.5 left-2.5 bg-forest-light rounded-full p-1">
                                <nuxt-img
                                    format="webp"
                                    densities="1x"
                                    width="150"
                                    quality="100"
                                    :src="getImageSuccessStory(story.id, story.imagePersonneHome ? 'personneHome' : 'personne')"
                                    class="shrink-0 h-full w-full object-cover rounded-full" loading="lazy"
                                    :alt="story.altImagePersonneHome ?? story.altImagePersonne">
                                </nuxt-img>
                            </div>
                        </div>

                        <div class="text-lg mb-8 sm:mb-10 font-semibold sm:min-h-[3rem] sm:overflow-hidden">
                            “ {{ story.frontData?.mainText }} ”
                        </div>
                    </div>

                    <div>
                        <div class="text-base text-grey8 mb-6 sm:mb-4">
                            {{ story.prenom }} {{ useVowelChecker(story.localisationClient, 'de') }},<br>
                            {{ story.age }} ans
                        </div>

                        <div class="flex items-center" v-if="story.frontData?.icone != null">
                            <div class="w-10 h-10 relative rounded bg-forest-light group-hover:bg-white mr-3">
                                <div
                                    class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-forest w-5 h-5">
                                    <icone-dynamique-composant
                                        :type="story.frontData?.icone.icon"></icone-dynamique-composant>
                                </div>
                            </div>

                            <div class="text-sm leading-5">
                                <div>
                                    {{ story.frontData?.icone.text }}
                                </div>

                                <div class="font-medium">
                                    {{ story.frontData?.icone.subText }}
                                </div>
                            </div>
                        </div>
                    </div>
                </nuxt-link>

                <div
                    class="flex flex-col items-center px-4 sm:px-12 bg-forest min-w-[17.625rem] sm:min-w-[21.563rem] rounded sm:mr-[.938rem] my-3 sm:mb-[2.625rem] sm:mt-[.625rem]">
                    <div class="w-fit h-fit mt-16">
                        <nuxt-img
                            format="webp"
                            densities="1x"
                            src='/images/success-story/groupeExpert.png'
                            class="rounded-full w-fit h-fit" loading="lazy"
                            width="350"
                            quality="100"
                            alt="Experts Maslow">
                        </nuxt-img>
                    </div>

                    <div class="text-white font-berlingske text-2xl text-center tracking-wide mt-8">
                        Retrouvez toutes les histoires de nos investisseurs
                    </div>

                    <c-button
                        class="cursor-pointer bg-white text-forest text-sm flex items-center align-center !px-8 sm:!py-2 mt-8 rounded font-medium"
                        :to="{name: 'success-story'}"
                    >
                        Toutes les histoires
                        <icons-arrow-narrow-right-outline class="w-6 ml-2"></icons-arrow-narrow-right-outline>
                    </c-button>
                </div>
            </c-frise>
        </div>
    </div>
</template>

<script setup>
import {getImageSuccessStory} from '~/composables/successStoryMixin';

defineProps({
    successStories: {
        type: Array,
        default: () => []
    }
});

const isMobile = toRef(useNuxtApp(), '$isMobile');
</script>

<style scoped lang="scss">
#container-frise-home2 {
    :deep(.container-fleches) {
        color: #004D43;
    }
}
</style>
